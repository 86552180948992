import React, { useEffect, useState } from "react";
import { Table, Spin, Button } from "antd";
import moment from "moment";
import { ExtensionItems } from "./extensionItems";
import { TopbarExtension } from "components/topbarExtension";
import { createApiClient } from "apiClient";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { ColumnsType } from "antd/es/table";
import { useDraftBillContext } from "contextApis/draftBillContext";

interface Billing {
  bill_created_date: string;
  billing_status: string;
  call_duration: number;
  caregem_bill_reference: number;
  code: string;
  desc: string;
  patient: string;
  patient_internal_id: number;
}

const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td {
    height: 52px;
    vertical-align: middle;
    text-align: center;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .patient-name {
    font-size: 14px;
    font-weight: bold;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const AllBillings: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredData, setFilteredData] = useState<any>([]);
  // const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  // const [selectedRecord, setSelectedRecord] = useState<Billing | null>(null);
  const navigate = useNavigate();
  const { setDraftBillId } = useDraftBillContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await createApiClient().getBillingsData();
        setData(response);

        // Set filtered data to only show drafts by default
        const drafts = response?.filter(
          (billing: any) => billing?.billing_status === "Draft"
        );
        setFilteredData(drafts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (
    filterType: string,
    searchValue: string,
    status: string
  ) => {
    setFilteredData([]);
    const filtered = data.filter((billing: any) => {
      const matchesCode =
        filterType === "Billing Code" || billing.code === filterType;
      const matchesName = billing.patient
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      const matchesStatus =
        status === "All" || billing.billing_status === status;

      return matchesCode && matchesName && matchesStatus;
    });

    setTimeout(() => {
      setFilteredData(filtered);
    });
  };

  const columns: ColumnsType<Billing> = [
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient",
      render: (text: string, record: Billing) => (
        <div
          className="patient-name"
          onClick={() => {
            navigate(`/patients/billing/${record.patient_internal_id}`);
          }}
          style={{ cursor: "pointer" }}
        >
          {text}
        </div>
      )
    },
    {
      title: "Date of Service",
      dataIndex: "date_p",
      key: "date_p",
      render: (date: string) => {
        const utcDate = new Date(date + "Z");
        const month = utcDate.toLocaleString("default", { month: "short" });
        const day = utcDate.getDate();
        const year = utcDate.getFullYear();
        const formattedDate = `${month} ${day}, ${year}`;
        return formattedDate;
      }
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code"
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc"
    },
    {
      title: "Call Duration (min)",
      dataIndex: "call_duration",
      key: "call_duration",
      render: (duration: number) => {
        const minutes = duration / 60;
        const fullMinutes = Math.floor(minutes);
        const seconds = Math.round((minutes - fullMinutes) * 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${fullMinutes}:${formattedSeconds}`;
      }
    },
    {
      title: "Billing Status",
      dataIndex: "billing_status",
      key: "billing_status",
      render: (status: string) => (status === "Approve" ? "Submitted" : status)
    },
    {
      title: "Action",
      key: "action",
      render: (record: Billing) =>
        record.billing_status === "Draft" ? (
          <Button
            style={{ borderRadius: "8px" }}
            type="primary"
            onClick={() => {
              navigate(`/patients/billing/${record.patient_internal_id}`);
              setDraftBillId(record.caregem_bill_reference.toString());
            }}
          >
            Edit
          </Button>
        ) : null
    }
  ];

  return (
    <>
      <TopbarExtension>
        <ExtensionItems onFilterChange={handleFilterChange} />
      </TopbarExtension>
      <div>
        {loading ? (
          <LoadingWrapper>
            <Spin tip="Loading..." />
          </LoadingWrapper>
        ) : (
          <StyledTable
            columns={columns as ColumnsType<object>}
            dataSource={filteredData}
            rowKey="caregem_bill_reference"
            pagination={false}
          />
        )}
      </div>
    </>
  );
};

export default AllBillings;
